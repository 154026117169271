import './App.css';
import ReadSaveFile from './components/ReadSaveFile/ReadSaveFile';


function App() {
  return (    
    <div className='App'>
      <ReadSaveFile/>
      <div className='footer'>
        <p>Разработчик Садыков Руслан Разимович</p>
        <p>2023 г</p>        
        </div>
    </div>
  );
}

export default App;
