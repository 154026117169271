import { useState ,useEffect} from "react";
import {sendCount,getCount} from '../../http/Api'

const ReadSaveFile = () => {
  const startStringParsing = "<DEBETDOCUMENTS>";
  const endStringParsig = "</DEBETDOCUMENTS>";

  const [fileName, setFileName] = useState("")
  const [countHandle,setCountHandle] = useState("");

  const encodeCP1251 = (string) => {
    function encodeChar(c) {
      var isKyr = function (str) {
        return /[а-я]/i.test(str);
      };
      var cp1251 =
        "ЂЃ‚ѓ„…†‡€‰Љ‹ЊЌЋЏђ‘’“”•–—�™љ›њќћџ ЎўЈ¤Ґ¦§Ё©Є«¬*®Ї°±Ііґµ¶·\
ё№є»јЅѕїАБВГДЕЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯабвгдежзийклмнопрстуфхцчшщъыьэюя";
      var p = isKyr(c) ? cp1251.indexOf(c) + 128 : c.charCodeAt(0);
      var h = p.toString(16);
      if (h === "a") {
        h = "0A";
      }
      return "%" + h;
    }
    var res = "";
    for (var i = 0; i < string.length; i++) {
      res += encodeChar(string.charAt(i)); //ну или string[i]
    }
    return res;
  };

  const handleNewFile = () => {
    if (!fileName) {
      alert("файл не выбран")
      return;
    }
    let reader = new FileReader();
    reader.readAsText(fileName, "CP1251");
    reader.onload = function () {
      let fileArr = reader.result.split("\n");
      let newFileArr = [];
      let flag = true;
      fileArr[0] = fileArr[0].replace(
        '<?xml version="1.0" encoding="windows-1251" ?>',
        '<?xml version="1.0" encoding="UTF-8"?>'
      );
      for (let i = 0; i < fileArr.length; ++i) {
        if (fileArr[i].includes(startStringParsing)) flag = false;
        if (fileArr[i].includes(endStringParsig)) {
          flag = true;
          fileArr[i] = fileArr[i].replace(endStringParsig, "<DEBETDOCUMENTS/>");
        }
        if (flag) newFileArr.push(fileArr[i]);
      }
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=UTF-8," + /*encodeCP1251(*/ encodeURI(newFileArr.join("\n"))
      );
      element.setAttribute("download", "new_" + fileName.name);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      changeCountHandle()
    };
  }
  const changeCountHandle = () =>
  {
    sendCount().then((data)=>setCountHandle(data.handlCount))
    .catch((err)=>console.log(err))

  }

  const handleChange = (event) => {
    //setFile(event.target.files[0])*/
    let file = event.target.files[0];
    setFileName(file);
  };

  useEffect(
    ()=>{getCount().then((data)=>setCountHandle(data.handlCount))
      .catch((err)=>console.log(err))
    }
    ,[])

  return (
    <main>
      <h1>Приложение для удаления из выписки исходящих платежей</h1>
      <h2>Как работать</h2>
      <ol>
        <li>Формируете xml выписку в ацк.</li>
        <li>Выбирайте файл выписки, и нажимаете обработать.</li>
        <li>Файл сразу обрабатывается и скачивается.</li>
        <li>Если браузер заблокировал скачивание, нажимаете сохранить.</li>
        <li>В БАРС грузите новый скаченный файл (имеет префикс new в имени файла).</li>
      </ol>
      <form>
        <label className="input-file">
          <span className="input-file-text" type="text">{fileName.name}</span>
          <input type="file" accept=".xml" onChange={handleChange} />
          <span className="input-file-btn br-left">Выберите файл</span>
        </label>
        <button type="submit" className="input-file-btn br-right" onClick={handleNewFile}>Обработать</button>
      </form>
      <p><a href="http://nche.pycel.ru/" target="_blank" rel="noreferrer">Ссылка</a> на инструкции по работе. А так же бота по поиску не пришедших документов из БАРС(для АУ/БУ учреждений г. Набережные Челны)</p>
      <p> За все время обработанно файлов: {countHandle}</p>
    </main>
  );
};
export default ReadSaveFile;
